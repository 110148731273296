<script lang="ts">
	import { dev } from '$app/environment'
	import { goto } from '$app/navigation'
	import FormStatus from '$components/FormStatus.svelte'
	import { createFormStatus } from '$stores/form.svelte'
	import { isMobile } from '$utils'

	const formStatus = createFormStatus({})

	const onclick = () => {
		formStatus.state = 'pending'
		if (!dev) {
			window.gtag('event', 'try_app_action', {
				isDesktop: !isMobile,
			})
		}
		goto('/login')
	}
</script>

<section id="app-idea">
	<div class="content">
		<div class="article">
			<p class="title">How did we came up with the idea of the app?</p>
			<p>
				The app was first conceived as an internal tool for a law firm specialized in company
				formations.
			</p>
			<p>
				The law firm generates a set of standard documents for each client, which are required
				to be filed with the relevant Trade Registry.
			</p>
			<p>
				Before the app was conceived, the average timeframe for drafting the documents was
				about 2 hours per client.
			</p>
			<p>
				Statistically, because the documents were manually prepared, which involved lots of
				copy pasting of basically the same information, human errors inevitably crept in.
			</p>
			<p>
				For example, some parts of the documents where the information was required to be
				inserted, were mistakenly left blank. Other times, the wrong information was copy
				pasted in a certain part. Sometimes, even worse, the information from past clients were
				forgotten within such documents.
			</p>
			<p>
				After the app was conceived, the average timeframe was reduced from 2 hours to 30
				minutes per client.
			</p>
			<p>
				Those time savings added up and by the end of the month, they were more or less
				equivalent to the work done by a full time junior lawyer.
			</p>
			<p>
				The human errors were also drastically reduced, since the client information was only
				required to be inserted once in one place (within the app). The app took care to put
				that information were it was required, without any mistakes.
			</p>
		</div>
		<button type="button" disabled={formStatus.disabled} {onclick}
			><span>Try it yourself</span>
			<FormStatus {formStatus} spinnerScale={0.7} spinnerOpaque={false} />
		</button>
	</div>
</section>

<style lang="scss">
	#app-idea {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4rem;

		background-image: url('/vectors/app_idea.svg');
		background-size: cover;
		box-shadow: 0px 0px 1rem 0.25rem rgba($black, 0.3);
		padding: 7rem 1rem;

		.content {
			display: flex;
			flex-direction: column;
			gap: 4rem;

			@include media-screen-desktop() {
				width: $desktop-content-width;
				align-self: center;
			}

			.article {
				display: flex;
				flex-direction: column;
				gap: 1rem;

				.title {
					font-size: 1.5rem;
					font-weight: bold;
				}
			}

			button {
				align-self: center;
				color: $valid-dark;
				background-color: transparent;
				border: 1px solid $valid-dark;
				padding: 1rem 1.5rem;
				border-radius: 0.5rem;
				font-weight: bold;
				box-shadow:
					inset 0 0 0.5rem $valid-dark,
					0 0 0.5rem $valid-dark;
				span {
					display: inline-block;
					font-size: 1.6rem;
					font-family: 'Bebas Neue';
					letter-spacing: 0.3rem;
					transform: translate(0.15rem);
				}
				&:hover {
					cursor: pointer;
					transform: scale(1.05);
					box-shadow:
						inset 0 0 0.7rem $valid-dark,
						0 0 0.7rem $valid-dark;
				}
				&:active,
				&:active:hover {
					transform: scale(0.97);
					box-shadow: none;
				}
				&[disabled] {
					color: $inactive;
					border-color: $inactive;
					box-shadow: none;
					&:hover {
						cursor: default;
						transform: none;
					}
				}
			}
		}
	}
</style>
