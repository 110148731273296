<script lang="ts">
	import { dev } from '$app/environment'
	import { goto } from '$app/navigation'
	import FormStatus from '$components/FormStatus.svelte'
	import { createFormStatus } from '$stores/form.svelte'
	import { isMobile } from '$utils'

	const formStatus = createFormStatus({})

	const onclick = () => {
		formStatus.state = 'pending'
		if (!dev) {
			window.gtag('event', 'try_app_action', {
				isDesktop: !isMobile,
			})
		}
		goto('/login')
	}
</script>

<section id="hero">
	<div class="content">
		<h1>Automate your Word document generation</h1>
		<h1>Replace your legal assistant at a fraction of the cost</h1>
		<button type="button" disabled={formStatus.disabled} {onclick}
			><span>Try it</span>
			<FormStatus {formStatus} spinnerScale={0.7} spinnerOpaque={false} />
		</button>
	</div>
</section>

<style lang="scss">
	#hero {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		z-index: 1;

		background-image: url('/vectors/hero_background_2.svg');
		background-size: cover;
		height: 100vh;
		box-shadow: 0px 0px 1rem 0.25rem rgba($black, 0.3);

		.content {
			display: flex;
			flex-direction: column;
			gap: 3rem;
			align-items: center;
			padding: 1rem;

			text-transform: uppercase;
			text-align: center;
			h1,
			span {
				font-size: 1.6rem;
				font-family: 'Bebas Neue';
				letter-spacing: 0.3rem;
				transform: translate(0.15rem);
			}
			h1 {
				color: $white;
				line-height: 1.2;
			}
			button {
				color: $valid;
				background-color: transparent;
				border: 1px solid $valid;
				padding: 1rem 1.5rem;
				border-radius: 0.5rem;
				font-weight: bold;
				box-shadow:
					inset 0 0 0.5rem $valid,
					0 0 0.5rem $valid;
				span {
					display: inline-block;
				}
				&:hover {
					cursor: pointer;
					transform: scale(1.05);
					box-shadow:
						inset 0 0 0.7rem $valid,
						0 0 0.7rem $valid;
				}
				&:active,
				&:active:hover {
					transform: scale(0.97);
					box-shadow: none;
				}
				&[disabled] {
					color: $inactive;
					border-color: $inactive;
					box-shadow: none;
					&:hover {
						cursor: default;
						transform: none;
					}
				}
			}
		}
	}
</style>
