<script lang="ts">
	let { ...props } = $props()
</script>

<svg
	{...props}
	xmlns="http://www.w3.org/2000/svg"
	xml:space="preserve"
	viewBox="0 0 485.331 485.331"
	><path
		d="m459.995 242.665 25.336-42.227-35.216-25.075 16.828-46.991-47.799-13.914 1.475-49.747-49.747 1.476-13.914-47.799-46.991 16.828L284.892 0l-42.227 25.336L200.439 0l-25.076 35.216-46.991-16.828-13.913 47.8-49.748-1.476 1.476 49.747-47.799 13.913 16.829 46.991L0 200.438l25.336 42.227L0 284.892l35.216 25.075-16.828 46.991 47.799 13.914-1.475 49.747 49.747-1.476 13.914 47.799 46.991-16.828 25.075 35.216 42.227-25.336 42.227 25.336 25.076-35.216 46.991 16.828 13.913-47.8 49.748 1.476-1.476-49.747 47.799-13.913-16.829-46.991 35.216-25.075-25.336-42.227zm-13.847 103.682-43.365 12.622 1.34 45.153-45.154-1.339-12.622 43.364-42.389-15.18L280.439 464l-37.773-22.664L204.892 464l-23.52-33.032-42.388 15.18-12.623-43.363-45.153 1.339 1.339-45.153-43.365-12.623 15.18-42.388-33.032-23.521 22.664-37.773-22.664-37.773 33.032-23.521-15.18-42.388 43.365-12.622-1.34-45.153 45.154 1.339 12.622-43.364 42.389 15.18 23.52-33.032 37.773 22.664 37.773-22.664 23.52 33.032 42.388-15.18 12.623 43.363 45.153-1.339-1.339 45.153 43.365 12.623-15.18 42.388L464 204.892l-22.664 37.773L464 280.438l-33.032 23.521 15.18 42.388z"
	/><path
		d="M242.666 58.665c-101.458 0-184 82.542-184 184s82.542 184 184 184 184-82.542 184-184-82.542-184-184-184zm0 352c-92.636 0-168-75.364-168-168s75.364-168 168-168 168 75.364 168 168-75.365 168-168 168z"
	/><path
		d="M242.666 90.665c-83.813 0-152 68.187-152 152s68.187 152 152 152 152-68.187 152-152-68.187-152-152-152zm0 288c-74.991 0-136-61.01-136-136s61.009-136 136-136 136 61.01 136 136-61.01 136-136 136z"
	/><path
		d="M268.896 172.961a31.816 31.816 0 0 0 5.769-18.296c0-17.645-14.355-32-32-32s-32 14.355-32 32c0 6.8 2.139 13.106 5.769 18.296a24.127 24.127 0 0 0-12.393 13.704h-73.376v16h15.16l-6.4 64h-8.76v16c0 17.645 14.355 32 32 32s32-14.355 32-32v-16h-8.76l-6.4-64h24.536a24.132 24.132 0 0 0 11.144 13.071l-12.931 25.862 22.912 106.921c1.756 8.197 9.115 14.146 17.499 14.146s15.743-5.949 17.499-14.146l22.912-106.921-12.931-25.862a24.128 24.128 0 0 0 11.144-13.071h24.536l-6.4 64h-8.76v16c0 17.645 14.355 32 32 32s32-14.355 32-32v-16h-8.76l-6.4-64h15.16v-16h-73.376a24.127 24.127 0 0 0-12.393-13.704zm-10.23 29.704h-32c-4.411 0-8-3.589-8-8s3.589-8 8-8h32c4.411 0 8 3.589 8 8s-3.589 8-8 8zm-16-64c8.822 0 16 7.178 16 16s-7.178 16-16 16-16-7.178-16-16 7.177-16 16-16zm-80 160c-8.822 0-16-7.178-16-16h32c0 8.822-7.178 16-16 16zm7.159-32h-14.32l6.4-64h1.52l6.4 64zm74.695 78.502c-.186.868-.966 1.498-1.854 1.498s-1.668-.63-1.854-1.498l-21.735-101.435 12.534-25.067h22.111l12.534 25.067-21.736 101.435zm78.146-46.502c-8.822 0-16-7.178-16-16h32c0 8.822-7.178 16-16 16zm7.159-32h-14.32l6.4-64h1.52l6.4 64z"
	/></svg
>

