<script lang="ts">
	let { ...props } = $props()
</script>

<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
	><g data-name="Layer 2"
		><path
			d="M11 14.25a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5h-.25V9a.75.75 0 0 0-.75-.75h-1a.75.75 0 0 0 0 1.5h.25v4.5ZM22 13.25a.75.75 0 0 0-.75.75v3A1.251 1.251 0 0 1 20 18.25H5c-.018 0-.033.009-.051.01a.73.73 0 0 0-.15.03.741.741 0 0 0-.13.046.732.732 0 0 0-.116.078.724.724 0 0 0-.114.1c-.011.014-.028.021-.04.036a.708.708 0 0 0-.037.08.717.717 0 0 0-.065.135.725.725 0 0 0-.03.15.699.699 0 0 0-.017.085c0 .018.009.033.01.05a.733.733 0 0 0 .03.152.532.532 0 0 0 .123.245.728.728 0 0 0 .102.114c.014.012.02.028.035.039l4 3a.75.75 0 1 0 .9-1.2l-2.2-1.65H20A2.753 2.753 0 0 0 22.75 17v-3a.75.75 0 0 0-.75-.75ZM22 7.25a.75.75 0 0 0-.75.75v3a.75.75 0 0 0 1.5 0V8a.75.75 0 0 0-.75-.75Z"
		/><path
			d="M2 16.75a.75.75 0 0 0 .75-.75V7A1.251 1.251 0 0 1 4 5.75h15a.83.83 0 0 0 .565-.271c.01-.012.025-.016.035-.029a.67.67 0 0 0 .036-.077.619.619 0 0 0 .097-.288A.671.671 0 0 0 19.75 5c0-.018-.009-.033-.01-.051a.811.811 0 0 0-.255-.51c-.014-.012-.02-.028-.035-.039l-4-3a.75.75 0 0 0-.9 1.2l2.2 1.65H4A2.753 2.753 0 0 0 1.25 7v9a.75.75 0 0 0 .75.75Z"
		/></g
	></svg
>
