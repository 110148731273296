<script lang="ts">
	let { ...props } = $props()
</script>

<svg {...props} xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 49 49"
	><path
		fill-rule="evenodd"
		d="M33.035 39.638v-2.592H36.1v2.592h-3.065zm-2.119-2.592v2.592h-3.298v-2.592h3.298zm-5.419 0v2.592h-3.298v-2.592h3.298zm-5.655 0v4.948h18.613V24.795h-5.42V13.958h7.776v31.334H17.487v-8.246h2.355zm13.193-9.896H36.1v2.827h-3.064V27.15zm0 4.95H36.1v2.826h-3.064V32.1zM15.13 37.046v10.603h28.037V11.601H33.035V1H5v36.046h10.13zM7.356 3.355H30.68V34.69H7.356V3.355z"
		clip-rule="evenodd"
	/></svg
>

