<script lang="ts">
	let { ...props } = $props()
</script>

<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
	><path
		d="M25.73 6.219a7.631 7.631 0 0 0-10.778 0 7.54 7.54 0 0 0-1.975 3.547 7.48 7.48 0 0 0-6.817 4.717l-2.108 5.272a.666.666 0 0 0 .619.914h3.165v3.165a2.586 2.586 0 0 0 2.583 2.584h2.208v.936a.667.667 0 1 0 1.333 0v-1.603a.667.667 0 0 0-.667-.666H10.42a1.252 1.252 0 0 1-1.25-1.25v-3.832a.666.666 0 0 0-.667-.667H5.655l1.742-4.358a6.148 6.148 0 0 1 5.375-3.863 7.499 7.499 0 0 0 6.562 8.016v8.223a.667.667 0 0 0 1.333 0v-8.157a7.552 7.552 0 0 0 5.064-2.2 7.632 7.632 0 0 0 0-10.778Zm-9.835 9.834a6.288 6.288 0 1 1 8.893 0 6.296 6.296 0 0 1-8.893 0Z"
	/><path
		d="M22.79 9.181a.666.666 0 0 0-.942 0l-1.487 1.487-1.506-1.507a.667.667 0 1 0-.943.943l1.506 1.507-1.504 1.504a.666.666 0 1 0 .943.942l1.504-1.504 1.484 1.485a.667.667 0 1 0 .943-.943l-1.484-1.484 1.487-1.487a.667.667 0 0 0 0-.943Z"
	/></svg
>

