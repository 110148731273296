<script lang="ts">
	let { ...props } = $props()
</script>

<svg {...props} xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 100 100"
	><path
		d="M66.3 56.7c-3.2 2.9-2.5 2.3 6.9 12.6 1.9 2.1 2.7.3 21.2-16.6.8-.8.9-2 .1-2.9-9.7-10.6-8.8-10.9-12.1-7.9L60.3 17.6c.9-.8 1.8-1.3 1.8-2.5.1-1.1.1-.7-8.6-10.3-.7-.8-2-.9-2.8-.1L32.3 21.4c-.8.8-.9 2-.1 2.9 9.5 10.5 8.8 10.9 11.9 8.1l4.7 5.2c-50.1 41.6-47 38.7-47.1 40 0 1.1-.5.2 14.2 16.4.8.8 2.1.9 2.9.1l42.7-42.6 4.8 5.2zm-48.8 33L6.7 77.9l44.9-37.3 7.2 7.9-41.3 41.2zM90.2 51 74.8 65l-5.4-5.9c1.3-1.1 13.5-12.3 15.4-14l5.4 5.9zM36.5 23.1 51.9 9l5.4 5.9c-1.9 1.7-13.5 12.3-15.4 14l-5.4-5.8zm20.8-2.8 22.1 24.3-10.2 9.3-22.1-24.3 10.2-9.3z"
	/><path
		d="M96.3 79.7H39.8c-1.1 0-2 .9-2 2v12.1c0 1.1.9 2 2 2h56.5c1.1 0 2-.9 2-2V81.7c0-1.1-.9-2-2-2zm-2.1 12.1H41.8v-8.1h52.5v8.1z"
	/></svg
>

