<script lang="ts">
	let { ...props } = $props()
</script>

<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="-5 -10 110 110"
	><path
		fill-rule="evenodd"
		d="M16.617 92.172H6.25a1.562 1.562 0 1 0 0 3.125h87.5a1.562 1.562 0 1 0 0-3.125H83.383V6.266c0-.863-.703-1.563-1.563-1.563H18.18c-.86 0-1.563.7-1.563 1.563zm63.641 0V7.828H19.742v84.344h15.242v-22.66c0-.863.7-1.563 1.562-1.563h26.906c.864 0 1.563.7 1.563 1.563v22.66zM51.563 71.074v21.098h10.328V71.074zM38.11 92.172h10.328V71.074H38.11zm9.266-50.652c0-.863-.7-1.563-1.563-1.563H28.454c-.863 0-1.562.7-1.562 1.563v17.359c0 .863.699 1.563 1.562 1.563h17.36c.862 0 1.562-.7 1.562-1.563zm25.734 0c0-.863-.7-1.563-1.563-1.563H54.188c-.863 0-1.562.7-1.562 1.563v17.359c0 .863.699 1.563 1.562 1.563h17.36c.862 0 1.562-.7 1.562-1.563zm-28.86 1.562v14.234H30.018V43.082zm25.735 0v14.234H55.75V43.082zm-22.61-26.477c0-.863-.699-1.562-1.562-1.562H28.454c-.863 0-1.562.7-1.562 1.562v17.36c0 .863.699 1.562 1.562 1.562h17.36c.862 0 1.562-.7 1.562-1.563zm25.735 0c0-.863-.7-1.562-1.563-1.562H54.188c-.863 0-1.562.7-1.562 1.562v17.36c0 .863.699 1.562 1.562 1.562h17.36c.862 0 1.562-.7 1.562-1.563zm-28.86 1.563v14.234H30.018V18.168zm25.735 0v14.234H55.75V18.168z"
	/></svg
>

