<script lang="ts">
	import { dev } from '$app/environment'
	import { goto } from '$app/navigation'
	import FormStatus from '$components/FormStatus.svelte'
	import HumanError from '$icons/HumanError.svelte'
	import LegalAssistant from '$icons/LegalAssistant.svelte'
	import MultipleDocuments from '$icons/MultipleDocuments.svelte'
	import Once from '$icons/Once.svelte'
	import Remember from '$icons/Remember.svelte'
	import WordExcel from '$icons/WordExcel.svelte'
	import { createFormStatus } from '$stores/form.svelte'
	import { isMobile } from '$utils'

	const formStatus = createFormStatus({})

	const onclick = () => {
		formStatus.state = 'pending'
		if (!dev) {
			window.gtag('event', 'try_app_action', {
				isDesktop: !isMobile,
			})
		}
		goto('/login')
	}
</script>

<section id="advantages">
	<div class="content">
		<ul class="advantages">
			<li class="advantage">
				<MultipleDocuments />
				<div class="description">
					<p class="title">Generate multiple documents with one click</p>
					<p>
						You just have to provide the template documents and the data. The documents will be
						generated all at once.
					</p>
				</div>
			</li>
			<li class="advantage">
				<Once />
				<div class="description">
					<p class="title">
						Write the data once and it will automatically be inserted in multiple documents.
					</p>
					<p>
						Save tens of hours of copy pasting the same data in multiple parts of many
						documents. Write the data once, and our app will insert it for you.
					</p>
				</div>
			</li>
			<li class="advantage">
				<HumanError />
				<div class="description">
					<p class="title">
						Our app removes human error when copy pasting the same data in multiple places.
					</p>
					<p>
						With our app, forgetting to insert the name of your client in all places in all
						documents is a thing of the past.
					</p>
				</div>
			</li>
			<li class="advantage">
				<Remember />
				<div class="description">
					<p class="title">
						Remember when you sent a document to a client having the information of your
						previous client?
					</p>
					<p>With our app this cannot happen.</p>
				</div>
			</li>
			<li class="advantage">
				<LegalAssistant />
				<div class="description">
					<p class="title">
						Replace the work of your legal assistant, junior lawyer or junior notary public.
					</p>
					<p>
						Save thousands of dollars paying a full time salary, by paying a hundred times less
						for our app.
					</p>
				</div>
			</li>
			<li class="advantage">
				<WordExcel />
				<div class="description">
					<p class="title">Import the data from Excel documents.</p>
					<p>
						It is easier for you working with Excel documents? No problem, you can get the data
						from there and our app will take care to insert it in your Word documents.
					</p>
				</div>
			</li>
		</ul>
		<button type="button" disabled={formStatus.disabled} {onclick}
			><span>Try our app</span>
			<FormStatus {formStatus} spinnerScale={0.7} spinnerOpaque={false} />
		</button>
	</div>
</section>

<style lang="scss">
	#advantages {
		background-image: url('/vectors/advantages_background_3.svg');
		background-size: cover;
		box-shadow: 0px 0px 1rem 0.25rem rgba($black, 0.3);
		padding: 7rem 1rem;

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 4rem;

			.advantages {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(9rem, 22rem));
				gap: 4rem;
				justify-content: center;
				align-self: stretch;

				@include media-screen-desktop() {
					width: $desktop-content-width;
					align-self: center;
				}

				.advantage {
					display: flex;
					gap: 0.5rem;
					align-items: start;

					:global(svg) {
						flex-basis: 4rem;
						flex-shrink: 0;
						fill: $valid-dark;
					}
					.description {
						.title {
							font-weight: bold;
							font-size: 1.1rem;
						}
					}
				}
			}
			button {
				color: $valid-dark;
				background-color: transparent;
				border: 1px solid $valid-dark;
				padding: 1rem 1.5rem;
				border-radius: 0.5rem;
				font-weight: bold;
				box-shadow:
					inset 0 0 0.5rem $valid-dark,
					0 0 0.5rem $valid-dark;
				span {
					display: inline-block;
					font-size: 1.6rem;
					font-family: 'Bebas Neue';
					letter-spacing: 0.3rem;
					transform: translate(0.15rem);
				}
				&:hover {
					cursor: pointer;
					transform: scale(1.05);
					box-shadow:
						inset 0 0 0.7rem $valid-dark,
						0 0 0.7rem $valid-dark;
				}
				&:active,
				&:active:hover {
					transform: scale(0.97);
					box-shadow: none;
				}
				&[disabled] {
					color: $inactive;
					border-color: $inactive;
					box-shadow: none;
					&:hover {
						cursor: default;
						transform: none;
					}
				}
			}
		}
	}
</style>
